
import AcademicEventGrade from '@/components/event/academic/AcademicEventGradeComponent.vue';
import EventGrid, {
  CellGradePayload,
  EventGridCell
} from '@/components/event/grid/EventGridComponent.vue';
import EventReasonSelector from '@/components/EventReasonSelectorComponent.vue';
import Icon from '@/components/icon/Icon';
import ManeuverReasonSelectorSearch from '@/components/ManeuverReasonSelectorSearchComponent.vue';
import ConfirmModalComponent from '@/components/modal/confirm/ConfirmModalComponent.vue';
import RecommendationEmailModal from '@/components/modal/recommendation/RecommendationEmailModalComponent.vue';
import ToastComponent from '@/components/ToastComponent.vue';
import {
  CreateGradedManeuverViewModel,
  Event,
  GradedEvent,
  GradedManeuver,
  Phase,
  Recommendation
} from '@/models';
import Routes from '@/router/Routes';
import { formatPhase } from '@/services/FormatService';
import store from '@/store';
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  watch
} from 'vue';
import { useRouter } from 'vue-router';
import eventMetaData from '../../../cms-generated/eventMetaData.json';
import Correction from '../../models/Correction';
import Reason from '../../models/Reason';
import { UserRole } from '../../models/UserRoles';

interface PhaseItem {
  phase: Phase;
  component: string;
}

type EventManeuverOperations = {
  delete: GradedManeuver[];
  update: GradedManeuver[];
  create: CreateGradedManeuverViewModel[];
};

export default defineComponent({
  components: {
    AcademicEventGrade,
    EventGrid,
    ManeuverReasonSelectorSearch,
    EventReasonSelector,
    RecommendationEmailModal,
    ConfirmModalComponent,
    ToastComponent
  },

  setup() {
    const student = computed(() => {
      return store.getters['studentModule/student'];
    });
    const unlockedEvents = computed(() => {
      return store.getters['eventModule/unlockedEvents'];
    });
    const studentEvents = computed(() => {
      return store.getters['studentModule/studentEvents'];
    });
    const studentRecommendations = computed(() => {
      return store.getters['studentModule/studentRecommendations'];
    });
    const maneuvers = computed(() => {
      return store.getters['eventModule/maneuvers'];
    });
    const readonly = computed(() => {
      return store.getters['userModule/currentUser'].role === UserRole.STUDENT;
    });
    const academicEvents = computed(() => {
      return store.getters['eventModule/syllabusAcademicEvents'];
    });
    const classData = computed(() => {
      return store.getters['classModule/currentClass'];
    });

    onMounted(async () => {
      const gradedEvents: GradedEvent[] = await store.dispatch(
        'studentModule/fetchStudentGradedEventsFullyQualified',
        {
          studentId: student.value.id,
          toggleSpinner: true
        }
      );
      store.dispatch('classModule/setCurrentClass', student.value.classId);
      store.dispatch('eventModule/fetchUnlockedEvents', {
        gradedEvents,
        syllabusId: classData.value.syllabusUrl
      });
      store.dispatch('studentModule/fetchRecommendations', student.value.id);
      store.dispatch('eventModule/fetchManeuvers', student.value.id);
      store.dispatch(
        'eventModule/fetchSyllabusAcademicEvents',
        classData.value.syllabusUrl
      );
    });
    const loading = ref(false);
    const recommendationModal = ref<
      InstanceType<typeof RecommendationEmailModal>
    >();
    const confirmDeleteEventModal = ref<
      InstanceType<typeof ConfirmModalComponent>
    >();
    const toastComponent = ref<InstanceType<typeof ToastComponent>>();
    const selectedEvent = ref();
    const selectedGridCell = ref();
    const reasons = ref([] as Reason[]);
    const corrections = ref([] as Correction[]);
    const comments = ref('');
    const router = useRouter();
    const selectedRecommendations = ref<Array<Recommendation>>([]);
    const hiddenRecommendations = ref<Array<Recommendation>>([]);
    let optionalFiltersReasonKeys = [] as string[];
    let optionalFiltersManeuverKeys = [] as string[];
    const lastSelectedEvent = ref();

    const syllabusUrl = computed(() => {
      return classData.value.syllabusUrl as keyof typeof eventMetaData.syllabus;
    });
    const syllabusMetaData = eventMetaData.syllabus[syllabusUrl.value];
    const phases = Object.keys(syllabusMetaData.phase);
    // TODO: SLP 756 Demo Phase Order hack - remove when fixed
    const phasesOrdered = [
      ...phases.filter((phase) => phase === 'preflight'),
      ...phases.filter((phase) => phase === 'qualification'),
      ...phases.filter((phase) => phase === 'mission'),
      ...phases.filter(
        (phase) =>
          phase !== 'mission' &&
          phase !== 'qualification' &&
          phase !== 'preflight'
      )
    ];

    const phaseItems = ref(
      phasesOrdered.map((phase) => {
        if (phase === 'preflight') {
          return {
            phase,
            component: 'academic-event-grade'
          };
        } else {
          return {
            phase,
            component: 'event-grid'
          };
        }
      }) as PhaseItem[]
    );

    const currentPhase = ref(phaseItems.value[0]);

    let hideSearch = ref(false);
    // Update state of reasons, corrections and comments when a new grid cell is selected
    watch(
      () => selectedGridCell.value,
      (gridCell: EventGridCell) => {
        if (gridCell && gridCell.maneuver) {
          reasons.value =
            gridCell.maneuver.reason.key !== ''
              ? [gridCell.maneuver.reason]
              : [];
          corrections.value =
            gridCell.maneuver.correction.key !== ''
              ? [gridCell.maneuver.correction]
              : [];
          comments.value = gridCell.maneuver.comments || '';
        }
        toggleSearch();
      }
    );

    watch(
      () => currentPhase.value,
      async () => {
        const gradedEvents: GradedEvent[] = await store.dispatch(
          'studentModule/fetchStudentGradedEventsFullyQualified',
          {
            studentId: student.value.id,
            toggleSpinner: true
          }
        );
        await store.dispatch('eventModule/fetchUnlockedEvents', {
          gradedEvents,
          syllabusId: classData.value.syllabusUrl
        });
      }
    );

    async function toggleSearch() {
      hideSearch.value = true;
      await nextTick();
      hideSearch.value = false;
    }

    function addReason(reason: Reason) {
      reasons.value[0] = reason;
      optionalFiltersReasonKeys[0] = 'reasonKey:' + reason.key;
      gradeEventManeuver();
    }

    function updateComments(c: string) {
      comments.value = c;
      gradeEventManeuver();
    }

    function addCorrection(selectedCorrection: Correction) {
      corrections.value[0] = selectedCorrection;
      gradeEventManeuver();
    }

    function removeReason() {
      reasons.value = [];
      optionalFiltersReasonKeys = [];
      gradeEventManeuver();
    }

    function removeCorrection() {
      corrections.value = [];
      gradeEventManeuver();
    }

    async function handleCreateEvent(e: Event) {
      const event = GradedEvent.fromJson({
        ...e
      });
      const eventId = await store.dispatch('studentModule/createEvent', {
        studentId: student.value.id,
        event: event
      });
      await fetchUpdatedEventData();
      const shouldNavToDetails = await toastComponent.value?.open(
        `Event ${event.name} created. Click here to grade.`
      );
      if (shouldNavToDetails) {
        router.push({
          name: Routes.STUDENT_EVENT_DETAILS,
          params: { eventId }
        });
      }
    }

    function updateGridCellManeuver(gradedEvents: GradedEvent[]) {
      try {
        const selectedEvent = gradedEvents.find((event) => {
          return event.id === selectedGridCell.value.event.id;
        });

        const selectedManeuver = selectedEvent?.maneuvers.find((maneuver) => {
          return maneuver.name === selectedGridCell.value.maneuver.name;
        });

        selectedGridCell.value.maneuver = selectedManeuver;
      } catch {
        return;
      }
    }

    async function fetchUpdatedEventData() {
      loading.value = true;
      try {
        const gradedEvents: GradedEvent[] = await store.dispatch(
          'studentModule/fetchStudentGradedEventsFullyQualified',
          {
            studentId: student.value.id
          }
        );

        await store.dispatch('eventModule/fetchUnlockedEvents', {
          gradedEvents,
          syllabusId: classData.value.syllabusUrl
        });
        await store.dispatch(
          'studentModule/fetchRecommendations',
          student.value.id
        );
        updateGridCellManeuver(gradedEvents);
      } catch (err) {
        //TODO slp-721 make me use the error handler
        throw new Error(
          `There was an error trying to fetch the student data ${err}`
        );
      }
      setTimeout(() => {
        loading.value = false;
      }, 500);
    }

    function getLastEventManeuverCount() {
      if (lastSelectedEvent.value) {
        return lastSelectedEvent.value.maneuvers.length;
      }
      return 0;
    }

    function getLastSelectedEventDetails():
      | { name: string; subject: string }
      | undefined {
      if (lastSelectedEvent.value) {
        return {
          name: lastSelectedEvent.value.name,
          subject: `Recommendations for ${student.value.firstName} ${student.value.lastName}`
        };
      }
      return undefined;
    }

    async function handleGradeEvent(event: GradedEvent) {
      await store.dispatch('studentModule/updateEvent', {
        studentId: student.value.id,
        event
      });
      await fetchUpdatedEventData();
    }

    async function gradeEventManeuver() {
      const gradedEvent = selectedGridCell.value.event;
      const maneuverViewModel = selectedGridCell.value.maneuver;

      const reason =
        reasons.value && reasons.value[0] ? reasons.value[0] : null;
      const correction =
        corrections.value && corrections.value[0] ? corrections.value[0] : null;
      maneuverViewModel.reason =
        reason || Reason.fromJson({ key: '', display: '' });
      if (correction) {
        maneuverViewModel.correction = correction;
      } else {
        maneuverViewModel.correction = Correction.fromJson({
          key: '',
          display: ''
        });
      }
      if (comments.value) {
        maneuverViewModel.comments = comments.value;
      }
      if (maneuverViewModel.type === GradedManeuver._type) {
        await store.dispatch('studentModule/updateEventManeuver', {
          studentId: student.value.id,
          eventId: gradedEvent.id,
          maneuver: GradedManeuver.fromJson(maneuverViewModel as GradedManeuver)
        });
      } else if (
        maneuverViewModel.type === CreateGradedManeuverViewModel._type
      ) {
        await store.dispatch('studentModule/createEventManeuver', {
          studentId: student.value.id,
          eventId: gradedEvent.id,
          maneuver: maneuverViewModel
        });
      }
    }

    async function handleEmailRecommendationEvent() {
      const gradedEvents: GradedEvent[] = await store.dispatch(
        'studentModule/fetchStudentGradedEventsFullyQualified',
        { studentId: student.value.id }
      );

      const event = gradedEvents.filter((event) => {
        if (event.id === lastSelectedEvent.value.id) {
          return event as GradedEvent;
        }
      });

      recommendationModal.value?.open(student.value, event[0]);
    }

    async function handleDeleteEvent(event: GradedEvent) {
      const shouldDelete = await confirmDeleteEventModal.value?.open(
        `Event ${event.name}`,
        `Are you sure you want to delete event ${event.name}?`,
        'Delete'
      );
      if (shouldDelete) {
        lastSelectedEvent.value = null;
        selectedEvent.value = null;
        selectedGridCell.value = null;
        await store.dispatch('studentModule/deleteEvent', {
          studentId: student.value.id,
          event
        });
      }
      await fetchUpdatedEventData();
    }

    async function handleGradeEventCollection(payload: CellGradePayload) {
      const events = payload.selectedEvents.map((event: GradedEvent) => {
        event.overallGrade = payload.gradeItem.grade || '';
        return event;
      });
      await store.dispatch('studentModule/updateEventCollection', {
        studentId: student.value.id,
        events
      });
    }

    async function handleGradeEvents(payload: CellGradePayload) {
      if (payload.selectedEvents.length) {
        await handleGradeEventCollection(payload);
        const gradedEvents: GradedEvent[] = await store.dispatch(
          'studentModule/fetchStudentGradedEventsFullyQualified',
          {
            studentId: student.value.id
          }
        );
        await store.dispatch('eventModule/fetchUnlockedEvents', {
          gradedEvents,
          syllabusId: classData.value.syllabusUrl
        });
      }
      if (payload.selectedGridCells.length) {
        await handleGradeEventManeuverCollection(payload);
      }
    }

    async function handleGradeEventManeuverCollection(
      payload: CellGradePayload
    ) {
      const eventManeuverOperations: EventManeuverOperations = {
        delete: [],
        update: [],
        create: []
      };
      /* Loop through all grid cells, push them into the delete, update or create array */
      payload.selectedGridCells.forEach((eventGridCell) => {
        const maneuver = eventGridCell.maneuver;
        maneuver.grade = payload.gradeItem.grade;
        /* If the grade is null, and the maneuver has been graded (not a create model) then remove the maneuver */
        const isDeleteOperation =
          !payload.gradeItem.grade && maneuver.type === GradedManeuver._type;
        /* If the grade is valid (not a null grade), and the maneuver exists (graded maneuver), then update the maneuver */
        const isUpdateOperation =
          payload.gradeItem.grade && maneuver.type === GradedManeuver._type;
        /* If the grade is valid (not a null grade), and the model is a create, then create the maneuver */
        const isCreateOperation =
          payload.gradeItem.grade &&
          maneuver.type === CreateGradedManeuverViewModel._type;
        /* Calulate payload and operation array based on operation type */
        let eventManeuverOperationCollection;
        let eventManeuverOperationPayload;
        if (isDeleteOperation) {
          eventManeuverOperationCollection = eventManeuverOperations.delete;
          eventManeuverOperationPayload = GradedManeuver.fromJson(
            maneuver as GradedManeuver
          );
        } else if (isUpdateOperation) {
          eventManeuverOperationCollection = eventManeuverOperations.update;
          eventManeuverOperationPayload = GradedManeuver.fromJson(
            maneuver as GradedManeuver
          );
        } else if (isCreateOperation) {
          eventManeuverOperationCollection = eventManeuverOperations.create;
          eventManeuverOperationPayload = CreateGradedManeuverViewModel.fromJson(
            maneuver as CreateGradedManeuverViewModel
          );
        }
        /* Push manuever to approppriate operations entry */
        if (eventManeuverOperationCollection && eventManeuverOperationPayload) {
          eventManeuverOperationCollection.push(eventManeuverOperationPayload);
        }
      });
      /* construct promises based off of eventManeuverOperations */
      const maneuverPromises: Promise<void>[] = [];
      Object.keys(eventManeuverOperations).forEach((operationType) => {
        const maneuvers =
          eventManeuverOperations[
            operationType as keyof EventManeuverOperations
          ];
        if (operationType === 'create' && maneuvers.length !== 0) {
          maneuverPromises.push(
            store.dispatch(`studentModule/addEventManeuverCollection`, {
              studentId: student.value.id,
              maneuvers
            })
          );
        } else if (operationType === 'update' && maneuvers.length !== 0) {
          maneuverPromises.push(
            store.dispatch(`studentModule/updateEventManeuverCollection`, {
              studentId: student.value.id,
              maneuvers
            })
          );
        } else if (operationType === 'delete' && maneuvers.length !== 0) {
          maneuverPromises.push(
            store.dispatch(`studentModule/deleteEventManeuverCollection`, {
              studentId: student.value.id,
              maneuvers
            })
          );
        }
      });
      await Promise.all(maneuverPromises);
    }

    function resetSelectedRecommendations() {
      selectedRecommendations.value = [];
      hiddenRecommendations.value = [];
    }

    function handleGridCellClick(eventGridCell: EventGridCell) {
      clearSelectedEvent();
      selectedGridCell.value = eventGridCell;

      if (eventGridCell) {
        lastSelectedEvent.value = eventGridCell.event;
        optionalFiltersManeuverKeys.push(
          'maneuverKey:' + eventGridCell.maneuver?.mif.formattedManeuver
        );
      } else {
        optionalFiltersManeuverKeys = [];
      }
    }

    function handleEventSelected(event: GradedEvent) {
      clearSelectedGridCell();
      selectedEvent.value = event;
      lastSelectedEvent.value = event;
    }

    function clearSelectedGridCell() {
      optionalFiltersManeuverKeys = [];
      selectedGridCell.value = null;
    }

    function clearSelectedEvent() {
      selectedEvent.value = null;
    }

    function handleEventNameClick(gradedEvent: GradedEvent) {
      router.push({
        name: Routes.STUDENT_EVENT_DETAILS,
        params: { eventId: gradedEvent.id }
      });
    }

    function handleRemoveRecommendation(recommendation: Recommendation) {
      const index = selectedRecommendations.value.findIndex(
        (rec) => rec === recommendation
      );
      if (index > -1) {
        selectedRecommendations.value.splice(index, 1);
      }
    }

    function handleScheduleRecommendation(recommendation: Recommendation) {
      selectedRecommendations.value.push(recommendation);
      hiddenRecommendations.value.push(recommendation);
    }

    function handleDiscardRecommendation(recommendation: Recommendation) {
      hiddenRecommendations.value.push(recommendation);
    }

    const computedEvents = computed(() => {
      return studentEvents.value.filter((event: GradedEvent) => {
        return event.phase === currentPhase.value.phase;
      });
    });

    const computedUnlockedEvents = computed(() => {
      // index unlocked events by event name
      let unlockedEventsByName = new Map<string, Event>();
      unlockedEvents.value.forEach((event: Event) => {
        unlockedEventsByName.set(event.name, event);
      });

      // filter out non-completed student graded events
      const gradedEventsFilteredByCompletion = studentEvents.value.filter(
        (gradedEvent: GradedEvent) => {
          return (
            gradedEvent.overallGrade !== '' && gradedEvent.overallGrade !== '1'
          );
        }
      );

      // index completed events by event name
      const completedEventsByName = new Map<string, GradedEvent>();
      gradedEventsFilteredByCompletion.forEach((gradedEvent: GradedEvent) => {
        completedEventsByName.set(gradedEvent.name, gradedEvent);
      });

      // filter out unlocked events by events that need to be completed
      let eventsFilteredByCompletedGradedEvents: Event[] = [];
      for (const [eventName, event] of unlockedEventsByName.entries()) {
        if (!completedEventsByName.has(eventName)) {
          eventsFilteredByCompletedGradedEvents.push(event);
        }
      }

      // filter out unlocked events that need to be completed by phase.
      const eventsFilteredByPhase = eventsFilteredByCompletedGradedEvents.filter(
        (event: Event) => {
          if (event) {
            return event.phase === currentPhase.value.phase;
          }
          return false;
        }
      );

      return eventsFilteredByPhase;
    });

    function handleError(error: string) {
      store.dispatch(`studentModule/setErrorMessage`, error);
    }

    return {
      handleError,
      student,
      unlockedEvents,
      studentEvents,
      studentRecommendations,
      maneuvers,
      readonly,
      academicEvents,
      formatPhase,
      clearSelectedGridCell,
      clearSelectedEvent,
      confirmDeleteEventModal,
      handleCreateEvent,
      handleDeleteEvent,
      handleGradeEvent,
      handleEventSelected,
      handleEventNameClick,
      handleGradeEvents,
      handleGridCellClick,
      addReason,
      addCorrection,
      updateComments,
      removeReason,
      removeCorrection,
      handleRemoveRecommendation,
      handleScheduleRecommendation,
      handleDiscardRecommendation,
      hideSearch,
      comments,
      Icon,
      loading,
      recommendationModal,
      selectedEvent,
      selectedGridCell,
      reasons,
      corrections,
      selectedRecommendations,
      hiddenRecommendations,
      resetSelectedRecommendations,
      optionalFiltersReasonKeys,
      optionalFiltersManeuverKeys,
      currentPhase,
      phaseItems,
      computedEvents,
      computedUnlockedEvents,
      handleEmailRecommendationEvent,
      getLastEventManeuverCount,
      getLastSelectedEventDetails,
      toastComponent
    };
  }
});
