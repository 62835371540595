
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import IconComponent from '@/components/icon/IconComponent.vue';
import { GradedEvent, GradeIconMap } from '@/models';
import ButtonComponent from './ButtonComponent.vue';
import { format } from 'date-fns';
import { commentMaxLength } from '../util/constraints';

export default defineComponent({
  components: {
    IconComponent,
    ButtonComponent
  },
  props: {
    event: {
      type: Object as PropType<GradedEvent>,
      required: true
    }
  },
  setup(props, { emit }) {
    const comments = ref(props.event.overallComments);

    const disabled = computed(() => {
      return !comments.value || comments.value === '';
    });

    watch(
      () => props.event,
      (event) => {
        comments.value = event.overallComments;
      }
    );

    function handleCommentsSelected() {
      if (comments.value.length > commentMaxLength) {
        emit('error', {
          type: 'Comment Text Overflow',
          message: `Overall Comments length must be less than ${commentMaxLength}`
        });
      } else {
        const event = GradedEvent.toJson(props.event);
        event.overallComments = comments.value;
        emit('gradeEvent', event);
      }
    }

    function handleCancel() {
      emit('cancel');
    }

    function formatDate(date: Date) {
      return format(date, 'LLL. d, yyy');
    }

    function handleSearch(text: string) {
      emit('search', text);
    }

    return {
      comments,
      disabled,
      commentMaxLength,
      formatDate,
      GradeIconMap,
      handleCancel,
      handleCommentsSelected,
      handleSearch
    };
  }
});
