
import ButtonComponent from '@/components/ButtonComponent.vue';
import Modal from '@/components/modal/ModalComponent.vue';
import { GradedEvent, GradeItem, gradeItems, Student } from '@/models';
import firebase from 'firebase';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  components: {
    ButtonComponent,
    Modal
  },
  setup() {
    let show = ref(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let resolve: any, reject: any;
    let recommendations = ref();
    let student = ref();
    let recommendationTables = ref();
    let subject = ref();
    let event = ref();

    function open(studentArg: Student, eventArg: GradedEvent) {
      show.value = true;
      student.value = studentArg;
      event.value = eventArg;
      recommendations.value = eventArg.maneuvers;

      subject.value = `Event ${event.value.name} - Recommendations for ${student.value.firstName} ${student.value.lastName}`;

      return new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });
    }

    function getGrade(eventKey: string) {
      const gradeItem: GradeItem | undefined = gradeItems.find(
        (gradeItem) => gradeItem.grade === eventKey
      );

      return gradeItem?.text;
    }

    async function confirm() {
      show.value = false;
      try {
        const element = recommendationTables.value as HTMLFormElement;
        await firebase
          .firestore()
          .collection('mail')
          .add({
            to: student.value.email,
            message: {
              html: element.innerHTML,
              subject: subject.value
            }
          });
        resolve(true);
      } catch (err) {
        reject(err);
      }
    }

    function cancel() {
      show.value = false;
      reject();
    }

    return {
      confirm,
      cancel,
      open,
      getGrade,
      recommendations,
      recommendationTables,
      show,
      subject,
      event
    };
  }
});
