import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-wrap justify-center gap-4 m-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_academic_grade_selector_component = _resolveComponent("academic-grade-selector-component")
  const _component_scroll_container = _resolveComponent("scroll-container")

  return (_openBlock(), _createBlock(_component_scroll_container, { class: "bg-primary-dark" }, {
    default: _withCtx(() => [
      _createVNode("section", _hoisted_1, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.academicEventItems, (academicEventItem, academicEventItemIndex) => {
          return (_openBlock(), _createBlock(_component_academic_grade_selector_component, {
            onClick: ($event: any) => (_ctx.gradeAcademicEvent(academicEventItem)),
            key: academicEventItemIndex,
            event: academicEventItem.academicEvent,
            complete: academicEventItem.complete,
            unlocked: academicEventItem.unlocked,
            readonly: _ctx.readonly,
            isLoading: academicEventItem.studentEvent.loading
          }, null, 8, ["onClick", "event", "complete", "unlocked", "readonly", "isLoading"]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}