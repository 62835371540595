
import { computed, defineComponent, PropType } from 'vue';
import ScrollContainer from '@/components/ScrollContainerComponent.vue';
import AcademicGradeSelectorComponent from '@/components/event/academic/AcademicGradeSelectorComponent.vue';
import { Event, GradedEvent } from '@/models';

type AcademicEventItem = {
  academicEvent: Event;
  studentEvent: GradedEvent;
  complete: boolean;
  unlocked: boolean;
};

export default defineComponent({
  emits: ['deleteEvent', 'createEvent'],
  components: {
    AcademicGradeSelectorComponent,
    ScrollContainer
  },
  props: {
    academicEvents: {
      type: Array as PropType<Array<Event>>,
      required: true
    },
    studentEvents: {
      type: Array as PropType<Array<GradedEvent>>,
      required: true
    },
    unlockedEvents: {
      type: Array as PropType<Array<Event>>,
      required: true
    },
    readonly: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    function gradeAcademicEvent(academicEventItem: AcademicEventItem) {
      if (academicEventItem.complete) {
        emit('deleteEvent', academicEventItem.studentEvent);
      } else {
        const gradedEvent = Event.toJson(academicEventItem.academicEvent);
        gradedEvent.overallGrade = '3';
        emit('createEvent', gradedEvent);
      }
    }

    const academicEventItems = computed(() => {
      return props.academicEvents.map((academicEvent) => {
        const studentEvent = props.studentEvents.find(
          (gradedEvent: GradedEvent) => gradedEvent.name === academicEvent.name
        );
        const studentEEvent = studentEvent ? studentEvent : { loading: false };
        return {
          academicEvent,
          studentEvent: studentEEvent,
          complete:
            props.studentEvents.filter(
              (gradedEvent: GradedEvent) =>
                gradedEvent.name === academicEvent.name && gradedEvent.complete
            ).length > 0,
          unlocked:
            props.unlockedEvents.findIndex(
              (e) => e.name === academicEvent.name
            ) > -1
        } as AcademicEventItem;
      });
    });

    return {
      gradeAcademicEvent,
      academicEventItems
    };
  }
});
