
import ButtonComponent from '@/components/ButtonComponent.vue';
import Modal from '@/components/modal/ModalComponent.vue';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  components: {
    ButtonComponent,
    Modal
  },
  setup() {
    const show = ref(false),
      modalHeadingText = ref(),
      modalMessageText = ref(),
      modalConfirmButtonText = ref(),
      modalCancelButtonText = ref();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let resolve: any;

    function open(
      headingText = 'Confirm',
      messageText = 'Are you sure you want to do this?',
      confirmButtonText = 'Confirm',
      cancelButtonText = 'Cancel'
    ) {
      modalHeadingText.value = headingText;
      modalMessageText.value = messageText;
      modalConfirmButtonText.value = confirmButtonText;
      modalCancelButtonText.value = cancelButtonText;
      show.value = true;
      return new Promise((res) => {
        resolve = res;
      });
    }

    function confirm() {
      resolve(true);
      show.value = false;
    }

    function cancel() {
      resolve(false);
      show.value = false;
    }

    return {
      cancel,
      confirm,
      open,
      show,
      modalConfirmButtonText,
      modalCancelButtonText,
      modalHeadingText,
      modalMessageText
    };
  }
});
