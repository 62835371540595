import { toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "max-h-screen overflow-y-auto" }
const _hoisted_2 = { class: "flex p-4 border-b" }
const _hoisted_3 = { class: "text-2xl font-semibold" }
const _hoisted_4 = { class: "p-4 border-b" }
const _hoisted_5 = { class: "flex items-center p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_component = _resolveComponent("button-component")
  const _component_modal = _resolveComponent("modal")

  return (_ctx.show)
    ? (_openBlock(), _createBlock(_component_modal, {
        key: 0,
        onClose: _ctx.cancel,
        "data-testid": "modal-overlay"
      }, {
        default: _withCtx(() => [
          _createVNode("section", _hoisted_1, [
            _createVNode("div", _hoisted_2, [
              _createVNode("div", _hoisted_3, _toDisplayString(_ctx.modalHeadingText), 1)
            ]),
            _createVNode("div", _hoisted_4, _toDisplayString(_ctx.modalMessageText), 1),
            _createVNode("div", _hoisted_5, [
              _createVNode("span", {
                class: "pr-6 ml-auto text-xs cursor-pointer",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args))),
                "data-testid": "cancel-button"
              }, _toDisplayString(_ctx.modalCancelButtonText), 1),
              _createVNode(_component_button_component, {
                onClick: _ctx.confirm,
                "data-testid": "confirm-button"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.modalConfirmButtonText), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["onClose"]))
    : _createCommentVNode("", true)
}