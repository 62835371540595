
import { defineComponent } from 'vue';
import Icon from '@/components/icon/Icon';
import { Event, GradeItem, gradeItems } from '@/models';

export default defineComponent({
  props: {
    event: {
      type: Event,
      required: true
    },
    complete: {
      type: Boolean,
      default: false
    },
    unlocked: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    function handleGradeSelected(gradeItem: GradeItem | null) {
      emit('gradeSelected', gradeItem);
    }

    return {
      gradeItems,
      handleGradeSelected,
      Icon
    };
  }
});
