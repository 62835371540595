
import { stringToCmsVariableName } from '@/util';
import { debounce } from 'lodash';
import { computed, defineComponent, PropType, ref } from 'vue';
import IconComponent from '@/components/icon/IconComponent.vue';
import {
  CreateGradedManeuverViewModel,
  GradedManeuver,
  GradeIconMap,
  ManeuverViewModel
} from '@/models';
import ButtonComponent from './ButtonComponent.vue';
import { format } from 'date-fns';
import AlgoliaSearchComponent from '@/components/search/AlgoliaSearchComponent.vue';
import AlgoliaCorrectionSearchComponent from '@/components/search/AlgoliaCorrectionSearchComponent.vue';
import ReasonSearchResult from '@/models/ReasonSearchResult';
import CorrectionSearchResult from '@/models/CorrectionSearchResult';
import Correction from '../models/Correction';
import Reason from '../models/Reason';
import config from '@/config';
import algoliaSearch from 'algoliasearch/lite';
import {
  commentMaxLength,
  reasonMaxLength,
  correctionMaxLength
} from '../util/constraints';

export default defineComponent({
  name: 'ManeuverReasonSelectorSearchComponent',
  components: {
    ButtonComponent,
    IconComponent,
    AlgoliaSearchComponent,
    AlgoliaCorrectionSearchComponent
  },
  props: {
    maneuver: {
      type: Object as PropType<GradedManeuver>,
      required: true
    },
    maneuverViewModel: {
      type: Object as PropType<
        ManeuverViewModel | CreateGradedManeuverViewModel
      >,
      required: true
    },
    event: {
      type: Object as PropType<Event>,
      required: true
    },
    reasons: {
      type: Array as PropType<Array<Reason>>
    },
    optionalFiltersReasonKeys: {
      type: Array as PropType<Array<string>>
    },
    optionalFiltersManeuverKeys: {
      type: Array as PropType<Array<string>>
    },
    corrections: {
      type: Array as PropType<Array<Correction>>
    },
    comments: {
      type: String,
      required: true
    }
  },
  emits: [
    'cancel',
    'addReason',
    'removeReason',
    'addCorrection',
    'removeCorrection',
    'updateComments',
    'error'
  ],
  setup(props, { emit }) {
    const commentCharacterCount = ref();
    const reasonCharacterCount = ref();
    const correctionCharacterCount = ref();
    const comment = ref(props.comments);
    const algoliaIndexPrefix = config.algolia.index.prefix;
    const reasonIndex = `${algoliaIndexPrefix}_reason`;
    const correctionIndex = `${algoliaIndexPrefix}_correction`;
    const searchClient = algoliaSearch(
      config.algolia.appId,
      config.algolia.apiKey,
      {}
    );

    const disabled = computed(() => {
      return false;
    });

    const debouncedCommentChange = debounce(handleCommentChange, 1000);

    function handleCancel() {
      emit('cancel');
    }

    function handleCommentChange(comments: string) {
      emit('updateComments', comments);
    }

    function updateComments(incomingComments: string) {
      comment.value = incomingComments;
      onInputChange(incomingComments, 'comments');

      if (incomingComments.length > commentMaxLength) {
        emit('error', {
          type: 'Comment Text Overflow',
          message: `Comments length must be less than ${commentMaxLength}`
        });
      } else {
        debouncedCommentChange(incomingComments);
      }
    }

    function formatDate(date: Date) {
      return format(date, 'LLL. d, yyy');
    }

    function removeReason(reasonKey: string) {
      emit('removeReason', reasonKey);
    }

    function removeCorrection(correction: Correction) {
      emit('removeCorrection', correction);
    }

    function handleReasonChange(reason: Reason) {
      if (reason.display.length > reasonMaxLength) {
        emit('error', {
          type: 'Reason Text Overflow',
          message: `Reason length must be less than ${reasonMaxLength}`
        });
      } else {
        reasonCharacterCount.value = '';
        emit('addReason', reason);
      }
    }

    function handleCorrectionChange(correction: Correction) {
      if (correction.display.length > correctionMaxLength) {
        emit('error', {
          type: 'Correction Text Overflow',
          message: `Correction length must be less than ${correctionMaxLength}`
        });
      } else {
        correctionCharacterCount.value = '';
        emit('addCorrection', correction);
      }
    }

    function onSelectedRecommendation(reason: ReasonSearchResult) {
      handleReasonChange(
        Reason.fromJson({
          key: reason.reasonKey,
          display: reason.reasonDisplay
        })
      );
    }

    function onReasonInputEnterPressed(query: string) {
      handleReasonChange(
        Reason.fromJson({
          key: stringToCmsVariableName(query),
          display: query
        })
      );
    }

    function onSelectedCorrection(
      correctionSearchResult: CorrectionSearchResult
    ) {
      handleCorrectionChange(
        Correction.fromJson({
          key: correctionSearchResult.correctionKey,
          display: correctionSearchResult.correctionDisplay
        })
      );
    }

    function onCorrectionInputEnterPressed(query: string) {
      handleCorrectionChange(
        Correction.fromJson({
          key: stringToCmsVariableName(query),
          display: query
        })
      );
    }

    function onInputChange(
      query: string,
      type: 'comments' | 'reason' | 'correction'
    ) {
      if (type === 'comments') {
        commentCharacterCount.value = `${query.length}`;
      } else if (type === 'reason') {
        reasonCharacterCount.value = `${query.length}`;
      } else if (type === 'correction') {
        correctionCharacterCount.value = `${query.length}`;
      }
    }

    return {
      onInputChange,
      commentMaxLength,
      reasonMaxLength,
      correctionMaxLength,
      comment,
      disabled,
      formatDate,
      GradeIconMap,
      handleCancel,
      updateComments,
      commentCharacterCount,
      reasonCharacterCount,
      correctionCharacterCount,
      debouncedCommentChange,
      onSelectedRecommendation,
      onSelectedCorrection,
      onCorrectionInputEnterPressed,
      onReasonInputEnterPressed,
      removeCorrection,
      removeReason,
      reasonIndex,
      correctionIndex,
      searchClient
    };
  }
});
